import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled from 'styled-components';
import vars from '../lib/styled';

const BackgroundPhotoWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 200px;
    width: 650px;

    @media (max-width: ${vars.breakpoint.laptop}) {
        right: -150px;
    }

    @media (max-width: ${vars.breakpoint.tablet}) {
        display: none;
    }
`;

const ContactBackgroundPhoto = ({ title, fluid }) => (
    <BackgroundPhotoWrapper aria-hidden={true}>
        <Image fluid={fluid} alt={title} />
    </BackgroundPhotoWrapper>
);

ContactBackgroundPhoto.propTypes = {
    fluid: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

export default ContactBackgroundPhoto;
