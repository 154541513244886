import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Image from 'gatsby-image';
import axios from 'axios';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { MdPhone, MdLocationOn } from 'react-icons/md';
import App from '../components/App';
import Main from '../components/Main';
import Hero from '../components/Hero';
import Input from '../components/Input';
import Grid, { TwoThirds } from '../components/Grid';
import Wrapper from '../components/Wrapper';
import { HeadingLevel3WithIcon } from '../components/Heading';
import Button from '../components/Button';
import Panel, { PanelContainer } from '../components/Panel';
import ContactBackgroundPhoto from '../components/ContactBackgroundPhoto';
import { ContactList, ContactListItem } from '../components/ContactList';
import lang from '../../static/lang.json';
import SEO from '../components/SEO';
import { extractHTML } from '../helpers/iterators';
import LocaleContext from '../components/Locale';
import Alert from '../components/Alert';
import HoneyPot from '../components/HoneyPot';
import vars from '../lib/styled';

export default ({ data }) => {
    const { site, page, contentfulAsset } = data;

    const locale = page.node_locale.toLowerCase();
    const [state, setState] = useState({
        submitted: false,
        success: true,
    });

    const {
        firstName,
        lastName,
        email,
        phone,
        street,
        streetline2,
        provinceOptions,
        city,
        province,
        postal,
        inquiry,
        feedback,
        feedbackOptions,
        submit,
        onSuccess,
        onFail,
    } = lang;

    const emptyForm = {
        [HoneyPot.ALTERNATIVE_NAME]: '',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        street: '',
        streetline2: '',
        city: '',
        postal: '',
        province: '',
        feedback: '',
        message: '',
    };

    return (
        <div
            style={{
                backgroundColor: vars.contrast,
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <App>
                <SEO {...page} />
                <LocaleContext.Consumer>
                    {() => (
                        <Helmet>
                            {page.node_locale !== 'en-CA' && (
                                <link
                                    rel="alternate"
                                    href="/en-ca/contact"
                                    hrefLang="en-CA"
                                />
                            )}
                            {page.node_locale !== 'fr-CA' && (
                                <link
                                    rel="alternate"
                                    href="/fr-ca/contactez-nous"
                                    hrefLang="fr-CA"
                                />
                            )}
                            {page.node_locale !== 'en-US' && (
                                <link
                                    rel="alternate"
                                    href="/en-us/contact"
                                    hrefLang="en-US"
                                />
                            )}
                        </Helmet>
                    )}
                </LocaleContext.Consumer>
                <Hero title={page.title} />
                <ContactBackgroundPhoto {...contentfulAsset} />

                <Main marginless>
                    <Formik
                        enableReinitialize
                        initialValues={emptyForm}
                        onSubmit={HoneyPot.execute(
                            (values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                axios
                                    .post('/.netlify/functions/contact', {
                                        ...values,
                                        emailAddress: values.email,
                                    })
                                    .then(() => {
                                        setSubmitting(false);
                                        resetForm(emptyForm);
                                        setState({
                                            submitted: true,
                                            success: true,
                                        });
                                    })
                                    .catch(() => {
                                        setSubmitting(false);
                                        setState({
                                            submitted: true,
                                            success: false,
                                        });
                                    });
                            },
                        )}
                        render={({ setFieldValue, isSubmitting }) => (
                            <TwoThirds>
                                <Form>
                                    <Grid>
                                        <Input
                                            title={firstName[locale]}
                                            type="text"
                                            name="firstName"
                                            required
                                        />
                                        <Input
                                            title={lastName[locale]}
                                            type="text"
                                            name="lastName"
                                        />
                                        <Input
                                            id="email"
                                            title={email[locale]}
                                            type="email"
                                            name={HoneyPot.ALTERNATIVE_NAME}
                                            required
                                        />
                                        <Input
                                            title={phone[locale]}
                                            type="tel"
                                            name="tel"
                                        />
                                        <Input
                                            title={street[locale]}
                                            type="text"
                                            name="street"
                                            required
                                        />
                                        <Input
                                            title={streetline2[locale]}
                                            type="text"
                                            name="streetline2"
                                        />
                                        <Input
                                            title={city[locale]}
                                            type="text"
                                            name="city"
                                            required
                                        />
                                        <Input
                                            title={province[locale]}
                                            component="select"
                                            name="province"
                                            required
                                        >
                                            <option value="">--</option>
                                            {Object.entries(
                                                provinceOptions[locale],
                                            ).map(([key, value]) => (
                                                <option key={key} value={key}>
                                                    {value}
                                                </option>
                                            ))}
                                        </Input>
                                        <Input
                                            title={postal[locale]}
                                            type="text"
                                            name="postal"
                                            required
                                        />
                                        <Input
                                            title={feedback[locale]}
                                            component="select"
                                            name="feedback"
                                            required
                                        >
                                            <option value="">--</option>
                                            {Object.entries(
                                                feedbackOptions[locale],
                                            ).map(([key, value]) => (
                                                <option key={key} value={key}>
                                                    {value}
                                                </option>
                                            ))}
                                        </Input>
                                        <Input
                                            title={inquiry[locale]}
                                            component="textarea"
                                            name="message"
                                            full
                                            required
                                        />
                                    </Grid>
                                    <HoneyPot />
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        primary
                                    >
                                        {submit[locale]}
                                    </Button>

                                    {state.submitted && (
                                        <Alert success={state.success}>
                                            {state.success
                                                ? onSuccess[locale]
                                                : onFail[locale]}
                                        </Alert>
                                    )}
                                </Form>
                            </TwoThirds>
                        )}
                    />
                </Main>

                <Wrapper style={{ marginTop: '4rem', position: 'relative' }}>
                    <Image
                    aria-hidden={true}
                        fluid={page.image.fluid}
                        style={{
                            bottom: 0,
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }}
                    />
                    <Panel
                        style={{
                            paddingTop: '4rem',
                            paddingBottom: '4rem',
                        }}
                    >
                        <PanelContainer>
                            <div {...extractHTML(page, 'body')} />
                            <Grid>
                                <div>
                                    <HeadingLevel3WithIcon>
                                        <MdPhone aria-hidden={true} />
                                        Contact
                                    </HeadingLevel3WithIcon>
                                    <ContactList>
                                        <ContactListItem>
                                            Tel:
                                            <a
                                                href={`tel:${site.siteMetadata.phone}`}
                                            >
                                                {site.siteMetadata.phone}
                                            </a>
                                        </ContactListItem>
                                        <ContactListItem>
                                            Fax:
                                            <a
                                                href={`tel:${site.siteMetadata.fax}`}
                                            >
                                                {site.siteMetadata.fax}
                                            </a>
                                        </ContactListItem>
                                        <ContactListItem>
                                            Toll Free:
                                            <a
                                                href={`tel:${site.siteMetadata.tollFree}`}
                                            >
                                                {site.siteMetadata.tollFree}
                                            </a>
                                        </ContactListItem>
                                    </ContactList>
                                </div>
                                <address>
                                    <HeadingLevel3WithIcon>
                                        <MdLocationOn aria-hidden={true} />
                                        Location
                                    </HeadingLevel3WithIcon>
                                    <ContactList>
                                        <ContactListItem>
                                            {site.siteMetadata.street}
                                        </ContactListItem>
                                        <ContactListItem>
                                            {site.siteMetadata.city},{' '}
                                            {site.siteMetadata.province}, CA
                                        </ContactListItem>
                                        <ContactListItem>
                                            {site.siteMetadata.postal}
                                        </ContactListItem>
                                    </ContactList>
                                </address>
                            </Grid>
                        </PanelContainer>
                    </Panel>
                </Wrapper>
            </App>
        </div>
    );
};

export const query = graphql`
    query getContactPageById($contentful_id: String, $node_locale: String) {
        ## config
        site {
            siteMetadata {
                phone
                fax
                tollFree
                street
                city
                province
                postal
            }
        }

        ## bg photo
        contentfulAsset(contentful_id: { eq: "21jnpS67jwCaawYmfironu" }) {
            title
            fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid
            }
        }

        ## locale contact inforamtion
        page: contentfulPage(
            contentful_id: { eq: $contentful_id }
            node_locale: { eq: $node_locale }
        ) {
            title
            description {
                description
            }
            node_locale
            body {
                childContentfulRichText {
                    html
                }
            }
            image {
                title
                fluid(maxWidth: 2200) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }
`;
