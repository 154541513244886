import styled from 'styled-components';
import vars from '../lib/styled';

export const ContactList = styled.ul`
    list-style: none;
    padding-left: 6rem;
    padding-right: 2rem;

    @media (max-width: ${vars.breakpoint.tablet}){
        padding: 0 1rem;
    }
`;

export const ContactListItem = styled.li`
    font-size: 1rem;
    margin-bottom: 0.2rem;

    a {
        color: #000;
        font-size: 1rem; 
        font-weight: 200;
        margin-left: .5rem;
        text-decoration: underline;
    }
`;
